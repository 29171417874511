/*
    When the stream is fullscreen, we must position an invisible overlay <div>
    with some whitespace content above the video and make it invisible to mouse
    events. If we don't place this overlay, the mouse cursor will automatically
    hide because the browser thinks the user is watching a full-screen video.
*/
#streamFullscreenContainer {
    width: min(176.9vh, 100%) !important;
    height: 100vh !important;
    margin-left: 50%;
    translate: -50%;
}

#streamFullscreenContainer:not(:fullscreen)>#streamFullscreenOverlay {
    display: none;
}

#streamFullscreenContainer:fullscreen>#streamFullscreenOverlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    pointer-events: none;
}

#streamVideoElement {
    height: 100%;
    object-fit: contain;
}

/* Scale video up to match container in fullscreen mode */
#streamFullscreenContainer:fullscreen>#streamVideoElement {
    width: 100%;
    height: 100%;
}

/* Prevent Safari from drawing media controls over stream */
#streamVideoElement::-webkit-media-controls {
    display: none !important;
}

html {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    background-color: #000;
    height: 100vh;
    margin: 0;
}

#desktop-controls {
    position: fixed;
    bottom: 5%;
    left: 5%;
    border-radius: 10px;
    padding: 10px 20px;
    z-index: 100000000;
    display: none;
    background: #111;
    color: #ccc;
    border: 2px solid #555;
}

#desktop-controls {
    display: block;
}

#desktop-controls ul {
    padding-left: 10px;
    margin: 0;
}

iframe,
#gamePlayContainer {
    width: min(176.9vh, 100%) !important;
    height: 100vh !important;
    margin-left: 50%;
    translate: -50%;
}

.loader {
    position: relative;
    width: 3vw;
    height: 3vw;
}

.loader:before,
.loader:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    inset: 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}

.loader:after {
    box-shadow: 0 2px 0 rgb(33, 188, 207) inset;
    animation: rotate .6s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

#loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: black;
    z-index: 1000000;
}

.started #loader {
    display: none;
}

#loader img {
    margin-bottom: 10vh;
}

.message {
    color: white;
    padding: 0 15vw;
    text-align: center;
    margin-top: 5vh;
}

.message a {
    color: rgb(33 188 207);
}

.progress {
    background-color: aliceblue;
    height: 1vh;
    width: 80vw;
    --done-percent: 5;
    margin-top: 5vh;
}

.progress .done {
    height: 100%;
    width: calc(var(--done-percent) * 0.8vw);
    background-color: #444;
    transition: width 4s linear;
}

.error-msg {
    margin-top: 5vh;
    color: red;
    font-weight: 600;
    letter-spacing: 1px;
}

#mobile-controls {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000000;
    display: none;
}

#mobile-controls [data-control] {
    border: 1px solid #000;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, .3);
    position: absolute;
}

[data-control="move"] {
    width: 100px;
    height: 100px;
    bottom: 10px;
    left: 10px;
}

[data-control="primary"] {
    width: 100px;
    height: 100px;
    bottom: 10px;
    right: 10px;
}

body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
}

h1 {
    background-color: #027;
    color: white;
    padding: .25em 1em;
    margin-left: -1em;
    margin-right: -1em;
}

#setupArgs,
#setupEnv {
    width: 300pt;
}

#setupClickToStart>button {
    margin-top: 1em;
    background-color: #070;
    color: white;
    padding: .25em 1em;
    font-size: 150%;
}

#setupValidationError {
    margin-top: 1em;
    font-weight: bold;
    color: darkred;
}

#appError {
    color: #fff;
    background: #d70202;
    padding: 5px 30px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#appError h1 {
    background-color: transparent;
}

#appDisconnected {
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#appDisconnected h1 {
    background-color: #333;
}

#appStreaming {
    position: relative;
}

#appStreaming h1 {
    background-color: #266;
    margin-bottom: 0;
}

#streamControls {
    margin: .25em 0;
}

#appConnecting h1 {
    background-color: #456;
    animation: connectingColorPulse 1s linear alternate-reverse infinite;
}

@keyframes connectingColorPulse {
    to {
        background-color: #058;
    }
}