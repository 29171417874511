.worldsPage {
  background-color: #2d393fcc;
  flex: 1;
  width: 100%;
}

.worldsHeader {
  width: 100%;
  padding: 0 50px;
  background-color: #141414;
  height: 70px;
  display: flex;
  align-items: center;
}

.worldsHeaderLogo {
  height: 50px;
}

.worldsBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #ffffff;
  padding: 0 50px;
}

.hrLine {
  background: #ffffff;
  justify-content: center;
  width: 100%;
  opacity: 0.3;
}

.logout-button {
  width: 185px;
  height: 50.5px;
  font-size: 24px;
  color: black;
  background-color: #4fe4f4;
  font-weight: 700;
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
}

.title {
  font-size: 30px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  justify-content: center;
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.worldWraper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.searchInput {
  width: 320px;
  height: 35px;
  border: 0.5 solid #ffffff66;
  font-size: 20px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 2.9rem;
  background: linear-gradient(0deg, #1b2226, #1b2226),
    linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
  text-overflow: ellipsis;
  font-family: "Roboto";
  color: white;
}

.searchBar {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding-left: 0.2rem;
}
