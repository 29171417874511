.world-name .world-persona {
  color: white;
  text-shadow: 2px 2px 4px #000000;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  margin: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Semi-transparent background for better readability */
}

.world-name {
  font-size: 15px;
}

.world-persona {
  font-size: 12px;
  font-weight: 100;
  opacity: 0.65;
}
.world-card {
  width: 24%;
  overflow: hidden;
  position: relative;
}

.card-image {
  width: 100%;
  height: auto;
  display: block;
}

.card-image-container {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-bottom: 16px;
}

.card-image:hover {
  text-shadow: 2px 2px 4px #000000;
  filter: brightness(0.7);
}
