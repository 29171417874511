body {
  background-color: black;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
html {
  --scrollbarBG: #141414;
  --thumbBG: #21bccf;
}
body::-webkit-scrollbar {
  width: 11px;
  /* height: 85px; */
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
}
* {
  font-family: "Roboto";
  box-sizing: border-box;
}
