.signinWrapper {
  background-image: linear-gradient(109deg, #0ba58e 2%, #041316 48%, #011613);
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
}
.signinMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  max-width: 500px;
}

.inputLabel {
  width: 100%;
  height: 10px;
  font-size: large;
  margin: 3px;
  color: #f3ebeb;
  text-align: left;
  box-sizing: border-box;
}

.inputCss {
  width: 100%;
  margin: 10px;
  padding: 15px;
  position: relative;
  border-radius: 5px;
  color: #000;
  background-color: #fffffffa;
  text-align: left;
  font-size: 22px;
  font-weight: 400;
  /* background-color: rgba(49, 128, 135, 0.2);  */
}

.loginButton {
  width: 100%;
  height: 50px;
  margin: 10px 0;
  padding: 0;
  border-radius: 8px;
  color: #0a0a0a;
  font-size: xx-large;
  background: #16bbb6;
}

.logoImage {
  width: 60%;
  max-width: 450px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  mix-blend-mode: color-dodge;
}

.loginHeader {
  color: white;
  text-align: center;
  font-size: 24px;
}

.introText {
  font-weight: 100;
  font-size: 2rem;
  text-align: center;
  color: white;
}

* {
  font-family: Roboto;
}
